import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function TechnologiesPage() {
  const [viewMode, setViewMode] = useState('frontend');

  const changeViewMode = (mode) => {
    setViewMode(mode);
  };

  return (
    <div>
      <div className="gradient-body">
        <section id="navbar" className="navbar-custom">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src="/assets/images/Authguard.png" alt="AuthGuard" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars" />
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      <i className="fa-solid fa-house" /> Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/aboutpage">
                      <i className="fa-solid fa-people-group" /> About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/servicespage">
                      <i className="fa-solid fa-toolbox" /> Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/projectspage">
                      <i className="fa-solid fa-images" /> Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/technologiespage">
                      <i className="fa-solid fa-microchip" /> Technologies
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contactpage">
                      <i className="fa-solid fa-headset" /> Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>

        <section
          className="section-padding page-title"
          style={{
            background: `linear-gradient(90deg, rgba(25,35,126,0.87718837535014) 0%, rgba(6,15,92,0.6923144257703081) 67%), url(/assets/images/about-page.png)`
          }}
        >
          <div className="container">
            <h2>Technologies</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fa-solid fa-chevron-right" />
              </li>
              <li>
                <Link to="/technologiespage">Technologies Page</Link>
              </li>
            </ul>
          </div>
        </section>
      </div>

      <section className="section-padding portfolio-area portfolio-area-v1 light-gray-bg pt-210 pb-130">
        <div className="container">
          <div className="scetion-title ">
            <img src="/assets/images/icon/innovation.png" alt="" />
            <h2 className="prime-title wow fadeInUp" data-wow-delay=".9s">
              Solutions with Technology
            </h2>
            <p>
              Empowering Solutions with Technology": This section emphasizes how
              your technology empowers users to solve problems and achieve their
              goals.
            </p>
          </div>

          <div className="tab-menu">
            <ul>
              {viewMode === 'frontend' ? (
                <li className="tablinks active-tab" id="defaultOpen" onClick={() => changeViewMode('frontend')}>
                  Front-End
                </li>
              ) : (
                <li className="tablinks" id="defaultOpen" onClick={() => changeViewMode('frontend')}>
                  Front-End
                </li>
              )}

              {viewMode === 'backend' ? (
                <li className="tablinks active-tab" onClick={() => changeViewMode('backend')}>
                  Back-End
                </li>
              ) : (
                <li className="tablinks" onClick={() => changeViewMode('backend')}>
                  Back-End
                </li>
              )}

              {viewMode === 'cloud' ? (
                <li className="tablinks active-tab" onClick={() => changeViewMode('cloud')}>
                  Cloud
                </li>
              ) : (
                <li className="tablinks" onClick={() => changeViewMode('cloud')}>
                  Cloud
                </li>
              )}

              {viewMode === 'database' ? (
                <li className="tablinks active-tab" onClick={() => changeViewMode('database')}>
                  Database
                </li>
              ) : (
                <li className="tablinks" onClick={() => changeViewMode('database')}>
                  Database
                </li>
              )}

              {viewMode === 'uiux' ? (
                <li className="tablinks active-tab" onClick={() => changeViewMode('uiux')}>
                  UI/UX
                </li>
              ) : (
                <li className="tablinks" onClick={() => changeViewMode('uiux')}>
                  UI/UX
                </li>
              )}
            </ul>
          </div>

          {viewMode === 'frontend' && (
            <div id="tab1" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/htmlLogo.png" alt="" />
                    <p>HTML</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/cssLogo.png" alt="" />
                    <p>CSS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/javascriptLogo.png" alt="" />
                    <p>Javascript</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/typescriptLogo.png" alt="" />
                    <p>Typescript</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/reactLogo.svg" alt="" />
                    <p>React JS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/vueLogo.svg" alt="" />
                    <p>Vue.js</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/angularLogo.svg" alt="" />
                    <p>Angular</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewMode === 'backend' && (
            <div id="tab2" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/javaLogo.svg" alt="" />
                    <p>Java</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/pythonLogo.svg" alt="" />
                    <p>Python</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/netcoreLogo.png" alt="" />
                    <p>.Net Core</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/nodeLogo.svg" alt="" />
                    <p>Node JS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/net.Logo.svg" alt="" />
                    <p>ASP.NET MVC</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/CSharpLogo.png" alt="" />
                    <p>C#</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/microserveLogo.jpg" alt="" />
                    <p>Microservice</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/nestjsLogo.png" alt="" />
                    <p>NestJS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/dockerLogo.png" alt="" />
                    <p>Docker</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewMode === 'cloud' && (
            <div id="tab3" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Cloud/azureLogo.svg" alt="" />
                    <p>Azure</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Cloud/gcpLogo.svg" alt="" />
                    <p>GCP</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Cloud/awsLogo.svg" alt="" />
                    <p>AWS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Cloud/kubernetesLogo.png" alt="" />
                    <p>Kubernetes</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewMode === 'database' && (
            <div id="tab4" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/mssqlLogo.png" alt="" />
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/mysqlLogo.png" alt="" />
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/postgresql.png" alt="" />
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/mongodbLogo.png" alt="" />
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/firebaseLogo.svg" alt="" />
                    <p>Firebase</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewMode === 'uiux' && (
            <div id="tab5" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/UiUx/figma.png" alt="" />
                    <p>Figma</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/UiUx/sketchLogo.png" alt="" />
                    <p>Sketch</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/UiUx/adobexdLogo.png" alt="" />
                    <p>Adobe XD</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="section-padding footer">
        <div className="container">
          <div className="footer-top">
            <div className="footer-box wow fadeInUp" data-wow-delay=".5">
              <div className="footer-icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="footer-text">
                <p>Colombo, Sri Lanka</p>
              </div>
            </div>
            <div className="footer-box wow fadeInUp" data-wow-delay=".9">
              <div className="footer-icon">
                <i className="fas fa-phone-alt" />
              </div>
              <div className="footer-text">
                <a href="tel:+94 777246384">+94 777246384</a>
              </div>
            </div>
            <div className="footer-box wow fadeInUp" data-wow-delay="1.3">
              <div className="footer-icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="footer-text">
                <a href="mailto:info@authguardtech.com">info@authguardtech.com</a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="fotter-bottom-iteam first-iteam-footer">
                  <h4>About Authguard</h4>
                  <p>
                    Our team is smart and experienced developers, consultants,
                    architects and designers. Our developers are adept at applying
                    the right technology for your business case.
                  </p>
                  <div className="socail-icon">
                    <ul>
                      <li>
                        <a href="https://web.facebook.com/profile.php?id=100066675601822">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/authguard/">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/66930786/admin/feed/posts/">
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer-copyright">
        <div className="container">
          <p>
            © <a href="https://www.zhoustify.com/"> Authguard</a> All Rights
            Reserved - 2024
          </p>
        </div>
      </section>
    </div>
  );
}

export default TechnologiesPage;

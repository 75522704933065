import React from "react";
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import HomePage from './components/HomePage';
import ProjectsPage from './components/ProjectsPage';
import ServicesPage from './components/ServicesPage';
import TechnologiesPage from './components/TechnologiesPage';
//import './assets/css/style.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutpage" element={<AboutPage />} />
        <Route path="/contactpage" element={<ContactPage />} />
        <Route path="/projectspage" element={<ProjectsPage />} />
        <Route path="/servicespage" element={<ServicesPage />} />
        <Route path="/technologiespage" element={<TechnologiesPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import 'animate.css/animate.min.css'; // Import Animate.css

function ContactPage() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <div>
      <div className="gradient-body">
        <section id="navbar" className="navbar-custom">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src="/assets/images/Authguard.png" alt="AuthGuard" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars" />
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      <i className="fa-solid fa-house" /> Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/aboutpage">
                      <i className="fa-solid fa-people-group" />
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/servicespage">
                      <i className="fa-solid fa-toolbox" />
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/projectspage">
                      <i className="fa-solid fa-images" />
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/technologiespage">
                      <i className="fa-solid fa-microchip" />
                      Technologies
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contactpage">
                      <i className="fa-solid fa-headset" />
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>
        <section
          className="section-padding page-title"
          style={{
            background: `linear-gradient(90deg, rgba(25,35,126,0.87718837535014) 0%, rgba(6,15,92,0.6923144257703081) 67%), url(/assets/images/about-page.png)`
          }}
        >
          <div className="container">
            <h2>Contact</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fa-solid fa-chevron-right" />
              </li>
              <li>
                <Link to="/projectpage">Contact</Link>
              </li>
            </ul>
          </div>
        </section>
      </div>

      <section className="section-padding contact">
        <div className="container">
          <div className="scetion-title ">
            <img src="/assets/images/icon/telephone.png" alt="" />
            <h2 className="prime-title wow fadeInUp" data-wow-delay=".9s">
              CONTACT US
            </h2>
            <p>
              If you have any inquiries please contact us and we’ll get back to you
              within 1 – 2 business days.
            </p>
          </div>
          <div className="row dis-flex">
            <div className="col-md-6">
              <div className="contact-form wow fadeInLeft" data-wow-delay=".7">
                <form action="">
                  <input type="text" placeholder="Name" />
                  <input type="number" placeholder="Phone number" />
                  <input type="email" placeholder="Email" />
                  <textarea
                    name=""
                    id=""
                    cols={30}
                    rows={10}
                    placeholder="Message.."
                    defaultValue={""}
                  />
                  <span>
                    <input type="submit" defaultValue="Send Message" />
                  </span>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-img  wow fadeInRight" data-wow-delay="1.3">
                <img src="/assets/images/contact.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-top-left animated-element">
          <img src="/assets/images/background-icon/message.png" alt="" />
        </div>
      </section>

      <section className="section-padding footer">
        <div className="container">
          <div className="footer-top">
            <div className="footer-box wow fadeInUp" data-wow-delay=".5">
              <div className="footer-icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="footer-text">
                <p>Colombo, Sri Lanka</p>
              </div>
            </div>
            {/* footer-box */}
            <div className="footer-box wow fadeInUp" data-wow-delay=".9">
              <div className="footer-icon">
                <i className="fas fa-phone-alt" />
              </div>
              <div className="footer-text">
                <a href="tel:+94 777246384">+94 777246384</a>
              </div>
            </div>
            {/* footer-box */}
            <div className="footer-box wow fadeInUp" data-wow-delay="1.3">
              <div className="footer-icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="footer-text">
                <a href="mailto:info@authguardtech.com">info@authguardtech.com</a>
              </div>
            </div>
            {/* footer-box */}
          </div>
          {/* footer-top */}
          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="fotter-bottom-iteam first-iteam-footer">
                  <h4>About Authguard</h4>
                  <p>
                    Our team is smart and experienced developers, consultants,
                    architects and designers. Our developers are adept at applying
                    the right technology for your business case.
                  </p>
                  <div className="socail-icon">
                    <ul>
                      <li>
                        <a href="https://web.facebook.com/profile.php?id=100066675601822">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/authguard/">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/66930786/admin/feed/posts/">
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer-copyright">
        <div className="container">
          <p>
            © <a href="https://www.zhoustify.com/"> Authguard</a> All Rights
            Reserved - 2024
          </p>
        </div>
      </section>
    </div>
  );
}

export default ContactPage;

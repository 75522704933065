import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ServicesPage() {
  const [viewMode, setViewMode] = useState('frontend');

  const changeViewMode = (mode) => {
    setViewMode(mode);
  };

  return (
    <div>
      <div className="gradient-body">
        <section id="navbar" className="navbar-custom">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src="/assets/images/Authguard.png" alt="AuthGuard" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars" />
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      <i className="fa-solid fa-house" /> Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/aboutpage">
                      <i className="fa-solid fa-people-group" /> About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/servicespage">
                      <i className="fa-solid fa-toolbox" /> Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/projectspage">
                      <i className="fa-solid fa-images" /> Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/technologiespage">
                      <i className="fa-solid fa-microchip" /> Technologies
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contactpage">
                      <i className="fa-solid fa-headset" /> Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>
        <section
          className="section-padding page-title"
          style={{
            background: `linear-gradient(90deg, rgba(25,35,126,0.87718837535014) 0%, rgba(6,15,92,0.6923144257703081) 67%), url(/assets/images/about-page.png)`
          }}
        >
          <div className="container">
            <h2>Services</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fa-solid fa-chevron-right" />
              </li>
              <li>
                <Link to="/servicespage">Services</Link>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <section className="section-padding service">
        <div className="container">
          <div className="scetion-title">
            <h3 className="sub-title  wow fadeInUp" data-wow-delay=".7s">
              <img src="/assets/images/icon/check-mark.png" alt="" /> WHAT WE DO
            </h3>
            <h2 className="prime-title wow fadeInUp" data-wow-delay=".9s">
              Transform your ideas into Reality
            </h2>
            <p>
              We strongly believe about making the world a better place using
              softwares and technologies
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInUp" data-wow-delay=".10ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/ochimkct.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#ffffff,secondary:#ffffff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>Software Development</h3>
                  <p>
                    We are specialized in developing secure, efficient web
                    application for all of your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInDown" data-wow-delay=".10ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/wcjauznf.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#ffffff,secondary:#ffffff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>Cloud Management</h3>
                  <p>
                    Applications Manager is a leading cloud usage monitor provider
                    offering effective cloud performance management and monitoring
                    solution. Applications Manager's cloud monitoring software will
                    give you complete visibility into the health and availability of
                    the entire cloud infrastructure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInUp" data-wow-delay=".10ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/jqeuwnmb.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#fff,secondary:#fff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>App Development</h3>
                  <p>
                    We build Mobile Applications that matches your business and
                    increase your productivity. We choose technology which is best
                    for your needs and your preferences. We have experience in
                    building native, cross platform Android and iOS applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInDown" data-wow-delay=".10ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/nobciafz.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#ffffff,secondary:#ffffff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>Web Development</h3>
                  <p>
                    We offer comprehensive web development consultancy for small to
                    medium enterprises, assisting them in transitioning part or all
                    of their business into the digital world. Our services encompass
                    everything from creating a custom website to implementing tailored
                    web solutions that perfectly align with your business needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInUp" data-wow-delay=".50ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/lzgmgrnn.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#ffffff,secondary:#ffffff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>UI/UX Design Concept </h3>
                  <p>
                    We specialize in UI/UX consultancy for small to medium enterprises, helping them seamlessly transition part or all of their
                    business into the digital realm. Our comprehensive services range from designing intuitive, user-friendly interfaces to implementing
                    customized solutions that perfectly align with your business needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* row */}
        </div>
        <div className="custom-shape-divider-top-1681121097">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            />
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            />
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            />
          </svg>
        </div>
        <div className="section-top-left animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
        <div className="section-center-right animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
      </section>

      <section className="section-padding footer">
        <div className="container">
          <div className="footer-top">
            <div className="footer-box wow fadeInUp" data-wow-delay=".5">
              <div className="footer-icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="footer-text">
                <p>Colombo, Sri Lanka</p>
              </div>
            </div>
            <div className="footer-box wow fadeInUp" data-wow-delay=".9">
              <div className="footer-icon">
                <i className="fas fa-phone-alt" />
              </div>
              <div className="footer-text">
                <a href="tel:+94 777246384">+94 777246384</a>
              </div>
            </div>
            <div className="footer-box wow fadeInUp" data-wow-delay="1.3">
              <div className="footer-icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="footer-text">
                <a href="mailto:info@authguardtech.com">info@authguardtech.com</a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="fotter-bottom-iteam first-iteam-footer">
                  <h4>About Authguard</h4>
                  <p>
                    Our team is smart and experienced developers, consultants,
                    architects and designers. Our developers are adept at applying
                    the right technology for your business case.
                  </p>
                  <div className="socail-icon">
                    <ul>
                      <li>
                        <a href="https://web.facebook.com/profile.php?id=100066675601822">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/authguard/">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/66930786/admin/feed/posts/">
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer-copyright">
        <div className="container">
          <p>
            © <a href="https://www.zhoustify.com/"> Authguard</a> All Rights
            Reserved - 2024
          </p>
        </div>
      </section>
    </div>
  );
}

export default ServicesPage;

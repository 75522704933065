import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import 'animate.css/animate.min.css'; // Import Animate.css

function ProjectsPage() {
  const [viewMode, setViewMode] = useState('frontend');

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const changeViewMode = (mode) => {
    setViewMode(mode);
  };

  const projects = [
    { src: '/assets/images/project/project-1.png', title: 'SharkTopUp', description: 'Online Recharge Platform' },
    { src: '/assets/images/project/project-2.jpg', title: 'EsportsPro', description: 'Gaming Services Platform' },
    { src: '/assets/images/project/project-3.jpg', title: 'LKFinder', description: 'Real Estate and Property Search Platform' },
    { src: '/assets/images/project/project-4.png', title: 'EasyTopUp', app: 'Mobile app', description: 'Convenient Mobile Recharge Platform' },
    { src: '/assets/images/project/project-6.png', title: 'Sense Media', app: 'Mobile app', description: 'Event Management Platform' },
    { src: '/assets/images/project/project-5.png', title: 'Fair Price Blinds', app: 'WordPress', description: 'Custom Window Blinds and Shades' },
  ];

  const renderProjects = () => {
    return (
      <div className="row portfolio-grid">
        {projects.map((project, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-12 portfolio-column mix cat-3">
            <div className="portfolio-item portfolio-style-one mb-50 wow fadeInUp" data-wow-delay=".10s">
              <div className="img-holder">
                <img src={project.src} alt="Img" />
                <a data-fancybox="portfolio-grid" href={project.src} className="portfolio-hover img-popup">
                  <div className="hover-content">
                    <lord-icon
                      src="https://cdn.lordicon.com/mecwbjnp.json"
                      trigger="hover"
                      delay={2000}
                      colors="primary:#ffffff,secondary:#ffffff"
                      style={{ width: 90, height: 90 }}
                    ></lord-icon>
                  </div>
                </a>
              </div>
              <div className="text">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="gradient-body">
        <section id="navbar" className="navbar-custom">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src="/assets/images/Authguard.png" alt="AuthGuard" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars" />
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      <i className="fa-solid fa-house" /> Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/aboutpage">
                      <i className="fa-solid fa-people-group" /> About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/servicespage">
                      <i className="fa-solid fa-toolbox" /> Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/projectspage">
                      <i className="fa-solid fa-images" /> Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/technologiespage">
                      <i className="fa-solid fa-microchip" /> Technologies
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contactpage">
                      <i className="fa-solid fa-headset" /> Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>
        <section
          className="section-padding page-title"
          style={{
            background: `linear-gradient(90deg, rgba(25,35,126,0.87718837535014) 0%, rgba(6,15,92,0.6923144257703081) 67%), url(/assets/images/about-page.png)`
          }}
        >
          <div className="container">
            <h2>Projects</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fa-solid fa-chevron-right" />
              </li>
              <li>
                <Link to="/projectspage">Projects</Link>
              </li>
            </ul>
          </div>
        </section>
      </div>

      <section className="section-padding portfolio-area portfolio-area-v1 light-gray-bg pt-210 pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="scetion-title">
              <h3 className="sub-title wow fadeInUp" data-wow-delay=".7s">
                <img src="/assets/images/icon/working.png" alt="" /> Latest Work
              </h3>
              <h2 className="prime-title wow fadeInUp" data-wow-delay=".9s">
                Professional Experience
              </h2>
              <p>
                We strongly believe about making the world a better place using
                softwares and technologies
              </p>
            </div>
          </div>
          {renderProjects()}
        </div>
        <div className="section-top-left animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
        <div className="section-center-right animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
      </section>

      <section className="section-padding footer">
        <div className="container">
          <div className="footer-top">
            <div className="footer-box wow fadeInUp" data-wow-delay=".5">
              <div className="footer-icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="footer-text">
                <p>Colombo, Sri Lanka</p>
              </div>
            </div>
            <div className="footer-box wow fadeInUp" data-wow-delay=".9">
              <div className="footer-icon">
                <i className="fas fa-phone-alt" />
              </div>
              <div className="footer-text">
                <a href="tel:+94 777246384">+94 777246384</a>
              </div>
            </div>
            <div className="footer-box wow fadeInUp" data-wow-delay="1.3">
              <div className="footer-icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="footer-text">
                <a href="mailto:info@authguardtech.com">info@authguardtech.com</a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="fotter-bottom-iteam first-iteam-footer">
                  <h4>About Authguard</h4>
                  <p>
                    Our team is smart and experienced developers, consultants,
                    architects and designers. Our developers are adept at applying
                    the right technology for your business case.
                  </p>
                  <div className="socail-icon">
                    <ul>
                      <li>
                        <a href="https://web.facebook.com/profile.php?id=100066675601822">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/authguard/">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/66930786/admin/feed/posts/">
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer-copyright">
        <div className="container">
          <p>
            © <a href="https://www.zhoustify.com/"> Authguard</a> All Rights
            Reserved - 2024
          </p>
        </div>
      </section>
    </div>
  );
}

export default ProjectsPage;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function AboutPage() {
  const [viewMode, setViewMode] = useState('frontend');

  const changeViewMode = (mode) => {
    setViewMode(mode);
  };

  return (
    <div>
      <div className="gradient-body">
        <section id="navbar" className="navbar-custom">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src="/assets/images/Authguard.png" alt="AuthGuard" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars" />
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      <i className="fa-solid fa-house" /> Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/aboutpage">
                      <i className="fa-solid fa-people-group" />
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/servicespage">
                      <i className="fa-solid fa-toolbox" />
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/projectspage">
                      <i className="fa-solid fa-images" />
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/technologiespage">
                      <i className="fa-solid fa-microchip" />
                      Technologies
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contactpage">
                      <i className="fa-solid fa-headset" />
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>

        <section
          className="section-padding page-title"
          style={{
            background: `linear-gradient(90deg, rgba(25,35,126,0.87718837535014) 0%, rgba(6,15,92,0.6923144257703081) 67%), url(/assets/images/about-page.png)`
          }}
        >
          <div className="container">
            <h2>About Us</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fa-solid fa-chevron-right" />
              </li>
              <li>
                <Link to="/aboutpage">About Us</Link>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <section className="section-padding about">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6">
              <div className="image_box wow fadeInLeft">
                <h2>Mission</h2>
                <p>Help businesses build new software solution that matters.</p>
                <h2>Vision</h2>
                <p>
                  To become a world-class software development and technology
                  provider to our clients.
                </p>
                <img src="/assets/images/about-page.png" alt="img" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="section-title left-align wow fadeInUp">
                <p className="about-subtitle">
                  <i className="fa-solid fa-book" />
                  What We Provide
                </p>
                <h3 className="title">WELCOME TO AUTHGUARD TECHNOLOGY</h3>
                <p>
                  Authguard Technologies founded to provide service to those who are
                  seeking high technologies to expand their business and ensure
                  their well being in the world. Our highly skilled professionals
                  are renowned for providing easy and workable solutions after
                  understanding client business objectives and specific market
                  conditions.
                </p>
                <p>
                  We promise that we will provide what you need.Our team ensures
                  total customer satisfaction and builds a long-term relationship.
                </p>
                <Link to="/servicespage" className="main-btn arrow-btn wow fadeInUp" data-wow-delay=".7s">
                  Our Services
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section-top animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
      </section>

      <section className="section-padding footer">
        <div className="container">
          <div className="footer-top">
            <div className="footer-box wow fadeInUp" data-wow-delay=".5">
              <div className="footer-icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="footer-text">
                <p>Colombo, Sri Lanka</p>
              </div>
            </div>
            {/* footer-box */}
            <div className="footer-box wow fadeInUp" data-wow-delay=".9">
              <div className="footer-icon">
                <i className="fas fa-phone-alt" />
              </div>
              <div className="footer-text">
                <a href="tel:+94 777246384">+94 777246384</a>
              </div>
            </div>
            {/* footer-box */}
            <div className="footer-box wow fadeInUp" data-wow-delay="1.3">
              <div className="footer-icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="footer-text">
                <a href="mailto:info@authguardtech.com">info@authguardtech.com</a>
              </div>
            </div>
            {/* footer-box */}
          </div>
          {/* footer-top */}
          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="fotter-bottom-iteam first-iteam-footer">
                  <h4>About Authguard</h4>
                  <p>
                    Our team is smart and experienced developers, consultants,
                    architects and designers. Our developers are adept at applying
                    the right technology for your business case.
                  </p>
                  <div className="socail-icon">
                    <ul>
                      <li>
                        <a href="https://web.facebook.com/profile.php?id=100066675601822">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/authguard/">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/66930786/admin/feed/posts/">
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer-copyright">
        <div className="container">
          <p>
            © <a href="https://www.zhoustify.com/"> Authguard</a> All Rights
            Reserved - 2024
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import 'animate.css/animate.min.css'; // Import Animate.css

function HomePage() {
  const [viewMode, setViewMode] = useState('frontend');

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const changeViewMode = (mode) => {
    setViewMode(mode);
  };

  const projects = [
    { src: '/assets/images/project/project-1.png', title: 'SharkTopUp ', description: 'Online Recharge Platform' },
    { src: '/assets/images/project/project-2.jpg', title: 'EsportsPro', description: 'Gaming Services Platform' },
    { src: '/assets/images/project/project-3.jpg', title: 'LKFinder', description: 'Real Estate and Property Search Platform' },
    { src: '/assets/images/project/project-4.png', app: 'Mobile app', title: 'EasyTopUp', description: 'Convenient Mobile Recharge Platform' },
    { src: '/assets/images/project/project-6.png', app: 'Mobile app', title: 'Sense Media', description: 'Event Management Platform' },
    { src: '/assets/images/project/project-5.png', app: 'WordPress', title: 'Fair Price Blinds', description: 'Custom Window Blinds and Shades' },
  ];

  const renderProjects = () => {
    return (
      <div className="row portfolio-grid">
        {projects.map((project, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-12 portfolio-column mix cat-3">
            <div className="portfolio-item portfolio-style-one mb-50 wow fadeInUp" data-wow-delay=".10s">
              <div className="img-holder">
                <img src={project.src} alt="Img" />
                <a data-fancybox="portfolio-grid" href={project.src} className="portfolio-hover img-popup">
                  <div className="hover-content">
                    <lord-icon
                      src="https://cdn.lordicon.com/mecwbjnp.json"
                      trigger="hover"
                      delay={2000}
                      colors="primary:#ffffff,secondary:#ffffff"
                      style={{ width: 90, height: 90 }}
                    ></lord-icon>
                  </div>
                </a>
              </div>
              <div className="text">
                <h3>{project.title}</h3>
                <h6>{project.app}</h6>
                <p>{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="gradient-body">
        <section id="navbar" className="navbar-custom">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src="/assets/images/Authguard.png" alt="AuthGuard" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars" />
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">
                      <i className="fa-solid fa-house" /> Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/aboutpage">
                      <i className="fa-solid fa-people-group" />
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/servicespage">
                      <i className="fa-solid fa-toolbox" />
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/projectspage">
                      <i className="fa-solid fa-images" />
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/technologiespage">
                      <i className="fa-solid fa-microchip" />
                      Technologies
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contactpage">
                      <i className="fa-solid fa-headset" />
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>
        {/*=========navbar-part-end========= */}
        {/*====== Start Banner Section ======*/}
        <section className="hero-banner-v2 position-relative z-1">
          <div className="shape shape-one">
            <span>
              <img src="/assets/images/hero-two-shape-1.png" alt="" />
            </span>
          </div>
          <div className="shape shape-two">
            <span>
              <img src="/assets/images/hero-two-shape-2.png" alt="" />
            </span>
          </div>
          <div className="shape shape-three">
            <span>
              <img src="/assets/images/hero-two-shape-2.png" alt="" />
            </span>
          </div>
          <div className="shape shape-four">
            <span>
              <img src="/assets/images/dot-pattern.png" alt="" />
            </span>
          </div>
          <div className="slider-scetion">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                      <div className="hero-content">
                        <span className="sub-title wow fadeInUp">
                          <i className="fas fa-arrow-right" />
                          Welcome to the Authguard
                        </span>
                        <h1 className="wow fadeInUp" data-wow-delay=".5s">
                          Strategic Design And Technology Agency
                        </h1>
                        <p className="wow fadeInDown" data-wow-delay="1s">
                          A small river named Duden flows by their place and
                          supplies it with the necessary regelialia. It is a
                          paradisematic country.
                        </p>
                        <Link to="/contactpage" className="main-btn arrow-btn wow fadeInUp" data-wow-delay=".7s">
                          Get in touch
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="hero-img wow fadeInRight" data-wow-delay=".9s">
                        <img src="/assets/images/hero1.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  {/* row */}
                </div>
                {/* carousel-item */}
                <div className="carousel-item">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                      <div className="hero-content">
                        <span className="sub-title wow fadeInUp">
                          <i className="fas fa-arrow-right" />
                          Welcome to the Authguard
                        </span>
                        <h1 className="wow fadeInUp" data-wow-delay=".5s">
                          Small Details Make A Big Impression
                        </h1>
                        <p className="wow fadeInDown" data-wow-delay="1s">
                          A small river named Duden flows by their place and
                          supplies it with the necessary regelialia. It is a
                          paradisematic country.
                        </p>
                        <a href="about.html" className="main-btn arrow-btn wow fadeInUp" data-wow-delay=".7s">
                          Get in touch
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="hero-img wow fadeInRight" data-wow-delay=".9s">
                        <img src="/assets/images/hero-img2.png" alt="" />
                      </div>
                    </div>
                  </div>
                  {/* row */}
                </div>
                {/* carousel-item */}
              </div>
              {/* carousel-inner */}
            </div>
            {/* carouselExampleIndicators */}
          </div>
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <i className="fa-solid fa-angle-left" />
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <i className="fa-solid fa-angle-right" />
          </a>
        </section>
        {/*====== End Banner Section ======*/}
      </div>
      {/*========== About Start========== */}
      <section className="section-padding about">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6">
              <div className="image_box wow fadeInLeft" data-wow-delay=".5s">
                <h2>Mission</h2>
                <p>Help businesses build new software solution that matters.</p>
                <h2>Vision</h2>
                <p>
                  To become a world-class software development and technology
                  provider to our clients.
                </p>
                <img src="/assets/images/about.svg" alt="img" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="section-title left-align wow fadeInUp" data-wow-delay=".7s">
                <p className="about-subtitle">
                  <i className="fa-solid fa-book" />
                  What We Provide
                </p>
                <h3 className="title">WELCOME TO AUTHGUARD TECHNOLOGY</h3>
                <p>
                  Authguard Technologies founded to provide service to those who are
                  seeking high technologies to expand their business and ensure
                  their well being in the world. Our highly skilled professionals
                  are renowned for providing easy and workable solutions after
                  understanding client business objectives and specific market
                  conditions.
                </p>
                <p>
                  We promise that we will provide what you need.Our team ensures
                  total customer satisfaction and builds a long-term relationship.
                </p>
                <Link to="/servicespage" className="main-btn arrow-btn wow fadeInUp" data-wow-delay=".7s">
                  Our Services
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section-top animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
      </section>
      {/*========== About End========== */}
      {/*========= service start========== */}
      <section className="section-padding service">
        <div className="container">
          <div className="scetion-title">
            <h3 className="sub-title wow fadeInUp" data-wow-delay=".7s">
              <img src="/assets/images/icon/check-mark.png" alt="" /> WHAT WE DO
            </h3>
            <h2 className="prime-title wow fadeInUp" data-wow-delay=".9s">
              Transform your ideas into Reality
            </h2>
            <p>
              We strongly believe about making the world a better place using
              softwares and technologies
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInUp" wow-data-delay=".10ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/ochimkct.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#ffffff,secondary:#ffffff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>Software Development</h3>
                  <p>
                    We are specialized in developing secure, efficient web
                    application for all of your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInDown" wow-data-delay=".10ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/wcjauznf.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#ffffff,secondary:#ffffff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>Cloud Management</h3>
                  <p>
                    Applications Manager is a leading cloud usage monitor provider
                    offering effective cloud performance management and monitoring
                    solution. Applications Manager's cloud monitoring software will
                    give you complete visibility into the health and availability of
                    the entire cloud infrastructure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInUp" wow-data-delay=".10ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/jqeuwnmb.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#fff,secondary:#fff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>App Development</h3>
                  <p>
                    We build Mobile Applications that matches your business and
                    increase your productivity. We choose technology which is best
                    for your needs and your preferences. We have experience in
                    building native, cross platform Android and iOS applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInDown" wow-data-delay=".10ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/nobciafz.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#ffffff,secondary:#ffffff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>Web Development</h3>
                  <p>
                    We offer comprehensive web development consultancy for small to
                    medium enterprises, assisting them in transitioning part or all
                    of their business into the digital world. Our services encompass
                    everything from creating a custom website to implementing tailored
                    web solutions that perfectly align with your business needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mar-bottom">
              <div className="service-iteam wow fadeInUp" wow-data-delay=".50ms">
                <div className="service-iteamleft">
                  <lord-icon
                    src="https://cdn.lordicon.com/lzgmgrnn.json"
                    trigger="loop"
                    delay={2000}
                    colors="primary:#ffffff,secondary:#ffffff"
                    style={{ width: 90, height: 90 }}
                  ></lord-icon>
                </div>
                <div className="service-iteam-right">
                  <h3>UI/UX Design Concept </h3>
                  <p>
                    We specialize in UI/UX consultancy for small to medium enterprises, helping them seamlessly transition part or all of their
                    business into the digital realm. Our comprehensive services range from designing intuitive, user-friendly interfaces to implementing
                    customized solutions that perfectly align with your business needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* row */}
        </div>
        <div className="custom-shape-divider-top-1681121097">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            />
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            />
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            />
          </svg>
        </div>
        <div className="section-top-left animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
        <div className="section-center-right animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
      </section>
      {/*===== service end========== */}
      {/*====== Start Portfolio Section ======*/}
      <section className="section-padding portfolio-area portfolio-area-v1 light-gray-bg pt-210 pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="scetion-title">
              <h3 className="sub-title wow fadeInUp" data-wow-delay=".7s">
                <img src="/assets/images/icon/working.png" alt="" /> Latest Work
              </h3>
              <h2 className="prime-title wow fadeInUp" data-wow-delay=".9s">
                Professional Experience
              </h2>
              <p>
                We strongly believe about making the world a better place using
                softwares and technologies
              </p>
            </div>
          </div>
          {renderProjects()}
        </div>
        <div className="section-top-left animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
        <div className="section-center-right animated-element">
          <img src="/assets/images/background-icon/3.png" alt="" />
        </div>
      </section>
      {/*====== End Portfolio Section ======*/}
      {/*========= technologies-start=========== */}
      <section className="section-padding tech">
        <div className="container">
          <div className="scetion-title ">
            <img src="/assets/images/icon/innovation.png" alt="" />
            <h2 className="prime-title wow fadeInUp" data-wow-delay=".9s">
              Solutions with Technology
            </h2>
            <p>
              Empowering Solutions with Technology": This section emphasizes how
              your technology empowers users to solve problems and achieve their
              goals.
            </p>
          </div>
          <div className="tab-menu">
            <ul>
              {viewMode === 'frontend' ? (
                <li className="tablinks active-tab" id="defaultOpen" onClick={() => changeViewMode('frontend')}>
                  Front-End
                </li>
              ) : (
                <li className="tablinks" id="defaultOpen" onClick={() => changeViewMode('frontend')}>
                  Front-End
                </li>
              )}

              {viewMode === 'backend' ? (
                <li className="tablinks active-tab" onClick={() => changeViewMode('backend')}>
                  Back-End
                </li>
              ) : (
                <li className="tablinks" onClick={() => changeViewMode('backend')}>
                  Back-End
                </li>
              )}

              {viewMode === 'cloud' ? (
                <li className="tablinks active-tab" onClick={() => changeViewMode('cloud')}>
                  Cloud
                </li>
              ) : (
                <li className="tablinks" onClick={() => changeViewMode('cloud')}>
                  Cloud
                </li>
              )}

              {viewMode === 'database' ? (
                <li className="tablinks active-tab" onClick={() => changeViewMode('database')}>
                  Database
                </li>
              ) : (
                <li className="tablinks" onClick={() => changeViewMode('database')}>
                  Database
                </li>
              )}

              {viewMode === 'uiux' ? (
                <li className="tablinks active-tab" onClick={() => changeViewMode('uiux')}>
                  UI/UX
                </li>
              ) : (
                <li className="tablinks" onClick={() => changeViewMode('uiux')}>
                  UI/UX
                </li>
              )}
            </ul>
          </div>

          {viewMode === 'frontend' && (
            <div id="tab1" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/htmlLogo.png" alt="" />
                    <p>HTML</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/cssLogo.png" alt="" />
                    <p>CSS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/javascriptLogo.png" alt="" />
                    <p>Javascript</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/typescriptLogo.png" alt="" />
                    <p>Typescript</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/reactLogo.svg" alt="" />
                    <p>React JS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/vueLogo.svg" alt="" />
                    <p>Vue.js</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Frontend/angularLogo.svg" alt="" />
                    <p>Angular</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewMode === 'backend' && (
            <div id="tab2" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/javaLogo.svg" alt="" />
                    <p>Java</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/pythonLogo.svg" alt="" />
                    <p>Python</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/netcoreLogo.png" alt="" />
                    <p>.Net Core</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/nodeLogo.svg" alt="" />
                    <p>Node JS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/net.Logo.svg" alt="" />
                    <p>ASP.NET MVC</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/CSharpLogo.png" alt="" />
                    <p>C#</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/microserveLogo.jpg" alt="" />
                    <p>Microservice</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/nestjsLogo.png" alt="" />
                    <p>NestJS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Backend/dockerLogo.png" alt="" />
                    <p>Docker</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewMode === 'cloud' && (
            <div id="tab3" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Cloud/azureLogo.svg" alt="" />
                    <p>Azure</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Cloud/gcpLogo.svg" alt="" />
                    <p>GCP</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Cloud/awsLogo.svg" alt="" />
                    <p>AWS</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Cloud/kubernetesLogo.png" alt="" />
                    <p>Kubernetes</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewMode === 'database' && (
            <div id="tab4" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/mssqlLogo.png" alt="" />
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/mysqlLogo.png" alt="" />
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/postgresql.png" alt="" />
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/mongodbLogo.png" alt="" />
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/Database/firebaseLogo.svg" alt="" />
                    <p>Firebase</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewMode === 'uiux' && (
            <div id="tab5" className="tabcontent wow fadeInUp" data-wow-delay=".9s">
              <div className="tab-box ">
                <div className="tech-icone-img">
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/UiUx/figma.png" alt="" />
                    <p>Figma</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/UiUx/sketchLogo.png" alt="" />
                    <p>Sketch</p>
                  </div>
                  <div className="tech-icone-itaem">
                    <img src="/assets/images/tech/UiUx/adobexdLogo.png" alt="" />
                    <p>Adobe XD</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* container */}
        <div className="custom-shape-divider-top-1681121097 shape-different">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            />
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            />
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            />
          </svg>
        </div>
      </section>
      {/*========= technologies-end=========== */}
      <section className="section-padding contact">
        <div className="container">
          <div className="scetion-title ">
            <img src="/assets/images/icon/telephone.png" alt="" />
            <h2 className="prime-title wow fadeInUp" data-wow-delay=".9s">
              CONTACT US
            </h2>
            <p>
              If you have any inquiries please contact us and we’ll get back to you
              within 1 – 2 business days.
            </p>
          </div>
          <div className="row dis-flex">
            <div className="col-md-6">
              <div className="contact-form wow fadeInLeft" data-wow-delay=".7">
                <form action="">
                  <input type="text" placeholder="Name" />
                  <input type="number" placeholder="Phone number" />
                  <input type="email" placeholder="Email" />
                  <textarea
                    name=""
                    id=""
                    cols={30}
                    rows={10}
                    placeholder="Message.."
                    defaultValue={""}
                  />
                  <span>
                    <input type="submit" defaultValue="Send Message" />
                  </span>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-img wow fadeInRight" data-wow-delay="1.3">
                <img src="/assets/images/contact.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-top-left animated-element">
          <img src="/assets/images/background-icon/message.png" alt="" />
        </div>
      </section>
      <section className="section-padding footer">
        <div className="container">
          <div className="footer-top">
            <div className="footer-box wow fadeInUp" data-wow-delay=".5">
              <div className="footer-icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="footer-text">
                <p>Colombo, Sri Lanka</p>
              </div>
            </div>
            {/* footer-box */}
            <div className="footer-box wow fadeInUp" data-wow-delay=".9">
              <div className="footer-icon">
                <i className="fas fa-phone-alt" />
              </div>
              <div className="footer-text">
                <a href="tel:+94 777246384">+94 777246384</a>
              </div>
            </div>
            {/* footer-box */}
            <div className="footer-box wow fadeInUp" data-wow-delay="1.3">
              <div className="footer-icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="footer-text">
                <a href="mailto:info@authguardtech.com">info@authguardtech.com</a>
              </div>
            </div>
            {/* footer-box */}
          </div>
          {/* footer-top */}
          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="fotter-bottom-iteam first-iteam-footer">
                  <h4>About Authguard</h4>
                  <p>
                    Our team is smart and experienced developers, consultants,
                    architects and designers. Our developers are adept at applying
                    the right technology for your business case.
                  </p>
                  <div className="socail-icon">
                    <ul>
                      <li>
                        <a href="https://web.facebook.com/profile.php?id=100066675601822">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/authguard/">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/66930786/admin/feed/posts/">
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer-copyright">
        <div className="container">
          <p>
            © <a href="https://www.zhoustify.com/"> Authguard</a> All Rights
            Reserved - 2024
          </p>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
